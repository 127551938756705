<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Create Associations</h3>

	<p>To create an <span v-html="link('case_associations', 'association')"> </span> for an item (domain, strand, cluster, or standard) in the framework/document:</p>
	<ul>
		<li><span v-html="link('signing_in', 'Sign in')"></span> to <span v-html="app_name"/> with Framework / System Admin or Editor permissions <span v-html="link('admin', ' (see Admin Functions)')"> </span>.</li>
		<li>Select a framework you want to edit to create an association.</li>
		<li><nobr><v-icon small>fas fa-edit</v-icon></nobr> Enter <strong>edit mode</strong> by clicking the <strong>Edit</strong> icon from the toolbar</li>
		<li>Select the item you want to edit in the framework tree (domain, strand, cluster, or standard)</li>
		<li>From the item, use the <strong>item card</strong> on the right to choose the <nobr><v-icon small>fas fa-edit</v-icon></nobr> <strong>EDIT</strong> button.</li>
		<li>From the <strong>edit menu</strong>, select <strong>MAKE ASSOCIATIONS.</strong></li> 
		<img srcset="/docimages/edit_menu.png 3x" class="k-help-img block">

		<br>
		
		<li>A <strong>Make Associations</strong> editor window will appear:</li>
		<ol class="k-no-clear" type="1">
			<img srcset="/docimages/make_assoc.png" class="k-help-img float-right">
			<li>Starting at the top, use the drop down menu to choose a framework you want to <strong>Associate Items to.</strong></li>
			<li>Next, select the <strong>Association Type</strong> (typically <strong>Precedes</strong> for internal vertical alignments or <strong>Related To</strong> for external one-way associations - See <span v-html="link('case_associations', 'CASE® Associations')"></span> for more info.).</li>
			<li>Use the <strong>Suggestion Criteria</strong> and search to find keywords from the framework to make item associations.</li>
			<li>Type your search words and choose SUGGEST if needed. Selecting an item from the suggestion criteria list will jump you to that area of the framework.</li>
			<li>Navigate the framework tree on the right to CHECK the <strong>destination item</strong>(s) to associate:
				<ul>
					<li>A <nobr><v-icon medium>fa-solid fa-check</v-icon></nobr> <strong>checkmark</strong> will appear next to the <Strong>destination item</Strong> according to the association type selected from the top of the <strong>Make Associations</strong> editor. 
						<ul>
							<li>Hover over the check to reveal an <nobr><v-icon small>fa-solid fa-x</v-icon></nobr> to <Strong>remove</Strong> an associated item.</li>
						</ul>
					</li>
					<li>An <img srcset="/docimages/association_label.png 2.3x " class="k-help-img"> <strong> association label</strong> will appear next to the <strong>origin item</strong> in the framework on the left with an association type icon and the corresponding color from the destination framework.
						<ul>
							<li>Select the <strong>X</strong> next to the association label to remove the association.</li>
						</ul>
					</li>
				</ul>
			</li>
		</ol>



	</ul>
	<p>See <span v-html="link('editing', 'Authoring/editing standards')"> </span>for details on other operations available from the editing menu or try out our <span v-html="link('batch_import_associations', 'batch import associations')"></span> if you already have the <span v-html="link('case_items', 'GUIDs')"></span> or <span v-html="link('case_items', 'Human Readable Codes')"></span> (HRCs) for the frameworks you want to associate.</p>
</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	